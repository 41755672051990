import { GeometryConfig } from "../scene/UnxSysNode";

export type NodeBodyStaticText = {
    text: string
};
export type NodeBodyStaticImage = {
    imageUrl: string,
    imageLabel: string,
};
export type NodeBodyVideoFile = {
    movUrl: string,
    movLabel: string,
};
export type NodeBodyStaticLink = {
    linkLabel?: string,
    linkUrl: string
}

export type NodeBodyStaticConfig = NodeBodyStaticText | NodeBodyStaticImage | NodeBodyStaticLink | NodeBodyVideoFile;
export type NodeStaticConfig = {
    title: string;
    title3d?: string;
    children?: NodeStaticConfig[];
    body?: NodeBodyStaticConfig[];
    geo?: GeometryConfig;
};

export default class JamesConfig {
    // INTERN
    private static readonly _navCog: NodeStaticConfig = {
        title: 'Nav Cog',
        title3d: 'nav_cog',
        children: [],
        body: [
            {text: `Title: Research Assistant`},
            {text: `Time: Summer 2016`},
            {text: `I supported development and testing of a tool to allow the visually impaired accurately and reliably navigate inside buildings. Performed data collection and analysis to enable accurate localization. Deployed and managed BLE beacons to allow for accurate location services in indoor spaces. Configured server to allow for real-time beacon data collection. Contributed to software development for iPhone app.`},
            {text: `The NavCog Project has since continued development, reaching full deployment in the Pittsburgh International Airport, the Tokyo Narita International Airport and several other locations.`},
            {linkUrl: 'https://www.cs.cmu.edu/~NavCog/navcog.html', linkLabel:`Read more about the project`}
        ],
        geo: {
            geoType: 'phish',
            points: [
                [
                    -0.5, 2,
                    -1.25, 1,
                    -0.5, 0,
                    -0.5,0.75,
                     1,0.75,
                     1,1.25,
                    -0.5,1.25,
                ],
            ],
        }
    }
    private static readonly _manTech: NodeStaticConfig = {
        title: 'Man Tech',
        title3d: 'mantech',
        children: [],
        body: [
            {text: `Title: Security Research Intern`},
            {text: `Time: Summer 2018`},
            {text: `Conducted security research (reverse-engineering and network forensics) against mobile platforms and support- ing infrastructure for clients and internal audits. Utilized knowledge of software, operating systems, and computer networks to detect and exploit vulnerabilities. Performed vulnerability research on embedded systems. Created plugins for existing software platforms to aid in research.`}
        ],
        geo: {
            geoType:'tbd',
            points:['>_', 0xc2200e]
        }
    }
    private static readonly _interns : NodeStaticConfig = {
        title: 'Internships',
        title3d: 'intern',
        children: [
            JamesConfig._navCog,
            JamesConfig._manTech
        ],
        body: [],
        geo: {
            geoType:'intern'
        }
    };
    // WORK
    private static readonly _tt: NodeStaticConfig = {
        title: 'TikTok',
        title3d: 'tiktok',
        children: [],
        body: [
            {text: `Title: Software Engineer, AR Tools`},
            {text: `Time: May 2021 - April 2024`},
            {text: `As a Software Engineer on the Effects Creation Tools Team, I worked to help to build Effect House, helping users to create and share interactive AR Effects on TikTok. My role involved working with the internal computer graphics engine, building out UX, and developing new features to further effect creation possibilities.`},
            {linkUrl: `https://effecthouse.tiktok.com`, linkLabel:'Check it out here!'},
            {linkUrl: `https://www.tiktok.com/@computerjames3d`, linkLabel:'checkout some effects I made with the tool'}
            
        ],
        geo: {
            geoType: 'tt',
            points: [
                [
                   -0.185, 0.277,
                   -0.351, 0.288,
                   -0.709,0.091,
                   -0.872, -0.327,
                   -0.706, -0.741,
                   -0.233, -0.903,
                    0.145, -0.732, 
                    0.316, -0.390,
                    0.322, 0.348,
                    0.740, 0.205,
                    0.763, 0.522,
                    0.433, 0.658,
                    0.316, 0.972,
                    0.003, 0.972,
                    0.003, -0.403,
                    -0.208,-0.586,
                    -0.465,-0.507,
                    -0.549,-0.318,
                    -0.467,-0.108,
                    -0.311,-0.035,
                    -0.191,-0.040,
                ],
                [
                    -0.191, 0.216,
                    -0.366, 0.171,
                    -0.618,0.056,
                    -0.780, -0.368,
                    -0.6, -0.767,
                    -0.233, -0.903,
                     0.145, -0.732, 
                     0.316, -0.390,
                     0.322, 0.348,
                     0.745, 0.210,
                     0.753, 0.466,
                     0.433, 0.658,
                     0.301, 0.877,
                     0.085, 0.875,
                     0.085, -0.449,
                     -0.177,-0.657,
                     -0.420,-0.545,
                     -0.549,-0.318,
                     -0.467,-0.108,
                     -0.311,-0.036,
                     -0.191,-0.040,
                 ],
            ],
        }
    }
    private static readonly _amzn: NodeStaticConfig = {
        title: 'Amazon',
        title3d: 'amzn',
        children: [],
        body: [
            {text: `Title: Software Development Engineer`},
            {text: `Prime Video, Playback Services`},
            {text: `Time: November 2019 - April 2021`},
            {text: `I spent the most of my time at Amazon working with Prime Video. My team manages the first point of interaction for the customer between selecting a title and initializing playback on Prime Video. I work in a variety of areas to support on of the largest video streaming platforms in the world. We maintain services that are massively scaled with high reliability. My work includes standard full stack software engineering, system design and integration, and support tooling.`},
            {text: `AWS, Support Engineering`},
            {text: `July 2019 - October 2019`},
            {text: `I started out at Amazon working with AWS Support Engineering. My team worked on a service that managed secure interaction with Amazon's largest corporate customers. This was my first time working on something at such a large scale, and was an extremely valuable learning experience.`},
            {imageUrl:'media/amazon-james.png', imageLabel:`me :)`}
        ],
        geo: {
            geoType: 'smile',
            points: [
                [
                    -2.184, 0.596,
                    -1.321, 0.247,
                    0,0,
                    1.067, 0.136,
                    1.796, 0.372,
                    1.123, -0.077,
                    0, -.31,
                    -1.399, 0
                ],
                [
                    1.303, 0.518,
                    1.796, 0.697,
                    2.222, 0.675,
                    2.177, 0.282,
                    1.874, -0.088,
                    2.009, 0.45,
                    1.897, 0.529,
                ]
            ],
        }
    }
    private static readonly _work : NodeStaticConfig = {
        title: 'Work Experience',
        title3d: 'work',
        children: [
            JamesConfig._tt,
            JamesConfig._amzn,
            JamesConfig._interns,
        ],
        body: [],
        geo: {
            geoType: 'brief'
        }
    };
    // PROJECTS
    private static readonly _scotty: NodeStaticConfig = {
        title: 'Scotty3D',
        title3d: 'scotty3D',
        children: [],
        body: [
            {text:`Scotty3D is a three-part assignment for CMU's Computer Graphics 15-462. The intent is to fully create a 3D modeling software from a very barebones framework. The final product is able to create and edit meshes, render realistic lighting with raytracing, and create animations to move meshes. `},
            {imageUrl:'media/apple.png', imageLabel:'Roughly Edited Mesh'},
            {imageUrl:'media/apple-subdiv.png', imageLabel:'After 2 Iterations of Catmull-Clark Subdivision'},
            {text:`The applications first part is a Mesh editor. This results in an applications capable of simple operations such as basic edge and vertex editing as well as more complex operations such as Beveling and Subdivisions. By combining various operations, we are able to create a very high polygon model.`},
            {text:`The next part of the process involved implementing a full Ray-Tracing renderer. The application uses a Bounding Volume Hierarchy to accelerate ray intersection calculations. Light traces may involve absorption, light bounces via Monte Carlo Ray-tracing, shadow ray-tracing as well as environmental lighting maps, all depending on the material properties of the model and scene. The renderer has support for Lambertian, Mirror and Metallic, as well as Glass-like and transparent surfaces implemented through Bidirectional Scattering Distribution Functions.`},
            {imageUrl:'media/lucy_mesh.png', imageLabel:'Polygonal Mesh'},
            {imageUrl:'media/bvh.gif', imageLabel:'BVH Generated'},
            {imageUrl:'media/lucy_render.png', imageLabel:'final render'},
            {imageUrl:'media/glass.png', imageLabel:'Glass Sphere with environmental light map'},
            {text:`Finally, the project concludes with an simple mesh animation set of tools. Features include motion paths with cubic spline interpolation, wave functions on a mesh surface, and pill capsule bound animation rigs with inverse kinematics`},
            {movUrl: 'media/animation.mp4', movLabel: 'A simple animation showcasing the Cubic Spline Paths'}
        ],
        geo: {
            geoType: 'scott'
        }
    };
    private static readonly _cgp: NodeStaticConfig = {
        title3d: 'comp_games',
        title: 'Computer Game Programming',
        children: [],
        body: [
            {text:`Senior year of College, I took a class called Computer Game Programming in which we essentially built our own game engines using C++ and OpenGL. Below are a compilation of several games I built in the class. They are all my own personal designs and all include assets I created for the games.`},
            {text:`Undercooked`},
            {text:`The first project I worked on was a simplified 1 player version of the game Overcooked, the main goal here was to get used to the OpenGL pipeline. In addition, this project involved figuring out how to export vertex colors using Blender. Relatively simple, the idea being to assemble a peanut butter and jelly sandwhich.`},
            {imageUrl:'media/cook.gif', imageLabel:'Gif of gameplay, mesh display'},
            {text:`The Gnarly Janitor`},
            {text:`This project entailed implementing walkmesh data structure as well as scene loading with transform hierarchies. The concept of this game is to clean up some messes and spills on at a skatepark.`},
            {imageUrl:'media/janitor.gif', imageLabel:'utilize walkmesh, local transforms'},
            {text:`Fish Off`},
            {text:`The game involved creating a multiplayer game using an online server. Although a relatively simple implementation, the server program handles a lot of the computation while the clients fill the drawing. The object in this game is to catch more fish than your opponent.`},
            {imageUrl:'media/fish.gif', imageLabel:'example gameplay, locally running multiple instances'},
            {text:`Sweet Darkness`},
            {text:`This project was my first major step into working with shaders using GLSL. This involved implementing shadow maps as well as working with textures. The object of this game is to keep the ice cream in the shadow as it orbits in space.`},
            {imageUrl: 'media/sweet_dark.gif', imageLabel: 'combing shaders and gameplay logic'}
        ],
        geo: {
            geoType:'tbd',
            points: ['gamer', 0x21ff25]
        }
    };
    private static readonly _faceRace: NodeStaticConfig = {
        title3d: 'face_race',
        title: 'Computer Game Programming',
        children: [],
        body: [
            {imageUrl:'media/face-race.png', imageLabel:'start screen'},
            {text:`Face Race was a group project I worked on as the Final Project for my Computer Game Programming class. The basic premise of the game was to manipulate a character's face to allow the player react to situations and give the character proper facial reactions.`},
            {text:`As the rest of the class, the game is written from scratch using C++ for engine and OpenGL/GLSL for graphics. The project also involved creating Shape Keys for the character mesh, which was done using Blender. This also involved being able to export the weights from the shapekeys using a python script, and subsequently import the weights into the application to allow for programmatic manipulation of the weights of the keys. The gameplay involves using the grab points to manipulate the shape keys and create the indicated facial expressions on the User Interface based on the prompts.`},
            {text: `Also implemented on the game is environment based lighting and a rim shader on the character, done using OpenGL and GLSL.`},
            {movUrl: 'media/face-race.mov', movLabel: 'Video of the described gameplay'}      
        ],
        geo: {
            geoType: 'faceRace'
        }
    };
    private static readonly _physh: NodeStaticConfig = {
        title3d: 'phish',
        title: 'Phish',
        children: [

        ],
        body: [
            {text: `The game itself is a basic top-down shooter style adventure game. The game follows a basic storyline: your goal is to collect data from users (with the information being pieces of data from Twitter users pulled via an API) and decode this data, eventually decrypting the relevant data to figure out crucial personal information.`},
            {text: `Essentially, the design was a play on the amount of information users post online without thinking using social media. One of my main goals was also to use the basic Pythongraphics package to create a visually desirable product. The above video showcases the basic funcionality of the game.`},
            {movUrl: 'media/phish.mp4', movLabel: 'Video of the described gameplay'}   
        ],
        geo: {
            geoType: 'phish',
            points: [
                [
                    -0.5, 0.5,
                    -1.3, 0,
                    -0.5,-0.5,
                    0.5, -0.05,
                    1.3, -0.4,
                    1.3, 0.4,
                    0.5, 0.05,
                ],
            ],
        }
    };
    private static readonly _projects : NodeStaticConfig = {
        title: 'Assorted Projects',
        title3d: 'projects',
        children: [
            JamesConfig._cgp,
            JamesConfig._scotty,
            JamesConfig._faceRace,
            JamesConfig._physh
        ],
        body: [],
        geo: {
            geoType:'cube'
        }
    };
    private static readonly _aboutMe : NodeStaticConfig = {
        title: 'About Me',
        title3d: '$JAMES',
        children: [
            JamesConfig._work,
            JamesConfig._projects,
        ],
        body: [
            {text: `Hello, my name is James Gualtieri, thanks for visiting my website!`},
            {text:`I'm currently a Software Development Engineer, based out of the San Francisco area. Most recently, I spent 3 years at TikTok building out a AR Effect creation tool from scratch. Before that, I graduated from Carnegie Mellon University, getting my Bachelor Degree in Electrical and Computer Engineering, and afterwards, I spent about 2 years working as a Software Engineer at Amazon Prime Video. My technical interests include Computer Graphics and Animation and Cyber Security. I really enjoy the intersection of technology with other industries, specifically in the entertainment sector.`},
            {text: `Outside of working with software, I enjoy film, playing video games, and keeping up with professional sports when I can. I've lived most of my life in Pittsburgh, but I like to travel and experience the world when I can. Also very much enjoy working with graphic design as well as 3D modeling, some film photography, and experimenting with different ways I feel like styling this website!`},
            {linkUrl: 'mailto:me@jame%73gual%74%69eri.co%6D', linkLabel: 'email me!'},
            {linkUrl: `https://www.linkedin.com/in/jamesgualtieri/`, linkLabel: 'linkedin'},
            {imageUrl: 'media/jimothy.jpg', imageLabel: '👍'}
        ],
        geo: {
            geoType: 'me'
        }
    }
    public static readonly rootNode = this._aboutMe;
}
