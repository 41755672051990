import * as THREE from 'three';
import UnxSysNode, { GeometryConfig } from '../scene/UnxSysNode';
import UnxSysAssetLibrary from '../scene/UnxSysAssetLibrary';
import {TextGeometry} from 'three/examples/jsm/geometries/TextGeometry';
import UnxSysView from '../scene/UnxSysView';
import { UnxSysReactUtil } from './UnxSysReactUtil';

export default class UnxSysGraphUtil {

    static toStr(vec: THREE.Vector3) {
        return `${vec.x},${vec.y},${vec.z}`;
    }

    static navigateFromTo(root: THREE.Object3D, start:THREE.Vector3, end:THREE.Vector3, step:number): THREE.Vector3 {
        const offset = root.position.clone().sub(start);
        const diff = end.clone().sub(start).multiplyScalar(step);
        const finalPostion = start.clone().add(offset.add(diff));
        root.position.copy(finalPostion);
        const retVal = start.clone().add(diff);
        return retVal;
    }

    static scaleFromTo(root: THREE.Object3D, start: number, end: number, step: number) {
        const diff = end - start;
        const final = start + diff * step;
        root.scale.setScalar(final);
        return final;
    }

    static addLabel(node: UnxSysNode, obj: THREE.Object3D, assetLib: UnxSysAssetLibrary) {
        const textGeo = new TextGeometry(node.name, {
            font: assetLib?.font!,
            size: 0.5,
            height: 0.1,
            curveSegments: 1,
            bevelEnabled: false
        });
        textGeo.computeBoundingBox();
        textGeo.computeVertexNormals();
        textGeo.center();

        const textMesh = new THREE.Mesh(
            textGeo,
            [assetLib.textMat, assetLib.textMatShadow]
        );
        textMesh.position.z = 2;
        textMesh.rotation.x = -Math.PI / 4;
        obj.add(textMesh);
    }

    static getNameObject(node: UnxSysNode): THREE.Mesh | undefined {
        if (node.object) {
            for (let i = 0; i < node.object.children.length; i++){
                const c = node.object.children[i];
                if (c instanceof THREE.Mesh && c.name === 'rot') {
                    return c;
                }
            }
        }
        return undefined;
    }

    static shapeFromPoints(pointsArr: Array<number>): THREE.Shape {
        const shape = new THREE.Shape();
        const startX = pointsArr[0];
        const startY = pointsArr[1];
        shape.moveTo(startX,startY);
        for (let i = 2; i < pointsArr.length; i+=2) {
            const x = pointsArr[i];
            const y = pointsArr[i+1];
            shape.lineTo(x, y);
        }
        shape.lineTo(startX, startY);
        return shape;
    }

    static textMesh(text: string, assetLib?: UnxSysAssetLibrary, color?: number): THREE.Object3D {
        const root = new THREE.Mesh();
        if (!assetLib) {
            return root;
        }
        const textGeo = new TextGeometry(text ?? '>_', {
            font: assetLib?.font!,
            size: 0.5,
            height: 0.1,
            curveSegments: 1,
            bevelEnabled: false
        });
        textGeo.computeBoundingBox();
        textGeo.computeVertexNormals();
        textGeo.center();
        const material = new THREE.MeshLambertMaterial({color: color?? Math.random() * 0xffffff});

        const textMesh = new THREE.Mesh(
            textGeo,
            [material, assetLib.textMatShadow]
        );
        textMesh.position.y = 1;
        root.add(textMesh);
        return root
    }

    static phish(points: Array<Array<number>>, assetLib?: UnxSysAssetLibrary): THREE.Object3D {
        if (points.length <1 || points[0].length < 2) {
            return new THREE.Mesh();
        }
        const shape1 = this.shapeFromPoints(points[0]);
        const extrudeSettings = assetLib?.extrudeSettings ?? {
            steps: 1,
            depth: 0.1,
            bevelEnabled: false,
        };
        const geometry1 = new THREE.ExtrudeGeometry( shape1, extrudeSettings );
        const material = new THREE.MeshLambertMaterial( { color: 0x00CC11 } );
        const mesh = new THREE.Mesh( geometry1, material ) ;
        mesh.position.y = 1;
        const root = new THREE.Mesh();
        root.add(mesh);
        root.scale.setScalar(0.45);
        return root
    }

    static smileMesh(points: Array<Array<number>>, assetLib?: UnxSysAssetLibrary): THREE.Object3D {
        if (points.length <1 || points[0].length < 2) {
            return new THREE.Mesh();
        }
        const shape1 = this.shapeFromPoints(points[0]);
        const shape2 = this.shapeFromPoints(points[1]);
        const extrudeSettings = assetLib?.extrudeSettings ?? {
            steps: 1,
            depth: 0.1,
            bevelEnabled: false,
        };
        const geometry1 = new THREE.ExtrudeGeometry( shape1, extrudeSettings );
        const geometry2 = new THREE.ExtrudeGeometry( shape2, extrudeSettings );
        const material = new THREE.MeshLambertMaterial( { color: 0x1388d1 } );
        const mesh = new THREE.Mesh( geometry1, material ) ;
        const mesh2 = new THREE.Mesh( geometry2, material ) ;
        mesh.position.y = 1;
        mesh2.position.y = 1;
        const root = new THREE.Mesh();
        root.add(mesh);
        root.add(mesh2);
        root.scale.setScalar(0.45);
        return root;
    }

    static ttMesh(points: Array<Array<number>>, assetLib?: UnxSysAssetLibrary): THREE.Object3D {
        if (points.length <1 || points[0].length < 2) {
            return new THREE.Mesh();
        }
        const shape1 = this.shapeFromPoints(points[0]);
        const extrudeSettings = assetLib?.extrudeSettings ?? {
            steps: 1,
            depth: 0.1,
            bevelEnabled: false,
        };
        const geometry1 = new THREE.ExtrudeGeometry( shape1, extrudeSettings );
        const material = new THREE.MeshLambertMaterial( { color: 0x00F1EA } );
        const material2 = new THREE.MeshLambertMaterial( { color: 0xFF0051 } );
        const mesh = new THREE.Mesh( geometry1, material ) ;
        const mesh2 = new THREE.Mesh( geometry1, material2 ) ;
        mesh.position.y = 2;
        mesh2.position.y = 1.919;
        mesh2.position.x = 0.091;
        const shape2 = this.shapeFromPoints(points[1]);
        const material3 = new THREE.MeshLambertMaterial( { color: 0x000000 } );
        const geometry3 = new THREE.ExtrudeGeometry( shape2, { steps: 1,depth: 0.2, bevelEnabled: false} );
        const mesh3 = new THREE.Mesh( geometry3, material3 );
        mesh3.position.y = 2;
        mesh3.position.z = -0.05;
        const root = new THREE.Mesh();
        root.add(mesh);
        root.add(mesh2);
        root.add(mesh3);
        root.scale.setScalar(0.45);
        return root;
    }

    static meMesh(assetLib?: UnxSysAssetLibrary): THREE.Mesh {
        const geo = new THREE.SphereGeometry( 0.5, 12, 6 );
        const material = new THREE.MeshLambertMaterial({color: 0x00CCCC});
        const mesh = new THREE.Mesh( geo, material );
        mesh.position.y = 0.25;

        const geo2 = new THREE.SphereGeometry( 0.25, 10, 6 );
        const mesh2 = new THREE.Mesh( geo2, material );
        mesh2.position.y = 0.75;
        mesh.add(mesh2);
        return mesh;
    }

    static briefCaseMesh(assetLib?: UnxSysAssetLibrary): THREE.Mesh {
        const geo = new THREE.BoxGeometry( 1, 0.2, 0.7 );
        const material = new THREE.MeshLambertMaterial({color: 0x4f2623});
        const basemesh = new THREE.Mesh( geo, material );
        basemesh.position.y = 0.5;
        const handle_geo = new THREE.BoxGeometry( 0.3, 0.1, 0.1 );
        const handle_material = new THREE.MeshLambertMaterial({color: 0x333333});
        const mesh = new THREE.Mesh( handle_geo, handle_material );
        mesh.position.z = 0.4;
        basemesh.add(mesh);
        return basemesh;
    }

    static faceRaceMesh(assetLib?: UnxSysAssetLibrary): THREE.Mesh {
        if (!assetLib) {
            return new THREE.Mesh();;
        }
        const root = new THREE.Mesh();
        const geo = new THREE.BoxGeometry( 1, 0.1, 0.2 );
        const mouth = new THREE.Mesh(geo, assetLib.blackMat);
        const geo2 = new THREE.SphereGeometry( 0.1, 8, 6 );
        const eyeL = new THREE.Mesh(geo2, assetLib.blackMat);
        eyeL.position.x = 0.25;
        const eyeR = new THREE.Mesh(geo2, assetLib.blackMat);
        eyeR.position.x = -0.25;
        mouth.position.y = -0.5;
        root.position.y = 1;

        root.add(eyeL);
        root.add(eyeR);
        root.add(mouth);
        return root;
    }

    static scottMesh(assetLib?: UnxSysAssetLibrary): THREE.Mesh {
        const root = new THREE.Mesh();
        if (!assetLib) {
            return root;
        }
        const floor = new THREE.PlaneGeometry( 1, 1 );
        const floorMesh = new THREE.Mesh(floor, assetLib.whiteMat);
        const wallMesh = new THREE.Mesh(floor, assetLib.whiteMat);
        const redMesh = new THREE.Mesh(floor, assetLib.redMat);
        const blueMesh = new THREE.Mesh(floor, assetLib.blueMat);
        floorMesh.rotation.x = -Math.PI / 2;
        floorMesh.position.y = -0.5;
        wallMesh.position.z = -0.5;
        root.add(floorMesh);
        root.add(wallMesh);
        redMesh.rotation.y = Math.PI / 2;
        redMesh.position.x = -0.5;
        root.add(redMesh);
        blueMesh.rotation.y = -Math.PI / 2;
        blueMesh.position.x = 0.5;
        root.add(blueMesh);

        const geo = new THREE.SphereGeometry( 0.25, 12, 6 );
        const material = new THREE.MeshLambertMaterial({color: 0xBBBBBB});
        const mesh = new THREE.Mesh( geo, material );
        root.add(mesh);
        root.position.y = 1;
        return root;
    }

    static internMesh(assetLib?: UnxSysAssetLibrary): THREE.Mesh {
        if (!assetLib) {
            return new THREE.Mesh();
        }

        const root = new THREE.Mesh();
        const floor = new THREE.PlaneGeometry( 0.75, 0.75 );
        const topMesh = new THREE.Mesh(floor, assetLib.blackMat);
        topMesh.rotation.x = -Math.PI / 2;
        const hat = new THREE.CylinderGeometry(0.25, 0.3, 0.5, 8, 1);
        const hatMesh = new THREE.Mesh(hat, assetLib.blackMat);
        topMesh.position.y = 0.25;
        root.position.y =1;
        root.add(topMesh);
        root.add(hatMesh);
        return root;
    }

    static getGeoFromConfig(config: GeometryConfig, assetLib?: UnxSysAssetLibrary): THREE.Object3D {
        switch (config.geoType) {
            case 'me': {
                return this.meMesh();
            }
            case 'brief': {
                return this.briefCaseMesh();
            }
            case 'scott': {
                return this.scottMesh(assetLib);
            }
            case 'faceRace': {
                return this.faceRaceMesh(assetLib);
            }
            case 'smile': {
                let pts = config.points as Array<Array<number>>;
                return this.smileMesh(pts, assetLib);
            }
            case 'tt': {
                let pts = config.points as Array<Array<number>>;
                return this.ttMesh(pts, assetLib);
            }
            case 'phish': {
                let pts = config.points as Array<Array<number>>;
                return this.phish(pts, assetLib);
            }
            case 'intern': {
                return this.internMesh(assetLib);
            }
            case 'tbd': {
                const text = config?.points?.[0] as string;
                const col = config?.points?.[1] as number;
                return this.textMesh(text, assetLib, col);
            }
            default: 
                const geometry = new THREE.BoxGeometry( 1, 1, 1 );
                const material = new THREE.MeshLambertMaterial({color: Math.random() * 0xffffff});
                const mesh = new THREE.Mesh( geometry, material );
                mesh.position.y = 1;
                return mesh;
        }
    }

    static generateMesh(node: UnxSysNode, startingPoint?: THREE.Vector3, assetLib?: UnxSysAssetLibrary): THREE.Object3D {
        const mainObject = UnxSysGraphUtil.defaultPlane(node.children?.length ?? 0, assetLib);
        assetLib && this.addLabel(node, mainObject, assetLib);
        if (node.geoConfig) {
            const mesh = this.getGeoFromConfig(node.geoConfig, assetLib);
            mesh.name = 'rot';
            mainObject.add(mesh);
        }
        mainObject.scale.setScalar(1 / node.depth);
        return mainObject;
    }

    static defaultPlane(width?: number, assetLib?: UnxSysAssetLibrary): THREE.Mesh {
        const factor = width ? width*2 : 2;
        const geometry = new THREE.BoxGeometry( factor, 0.5, 3);
        const material = new THREE.MeshPhongMaterial({color: assetLib?.pinkColor ?? 0x2ef26c});
        return new THREE.Mesh( geometry, material );
    }

    static connectPoints(scene: THREE.Object3D, p1: THREE.Vector3, p2: THREE.Vector3) {
        const material = new THREE.LineBasicMaterial( { color: 0xffffff } );
        const geometry = new THREE.BufferGeometry().setFromPoints([p1,p2]);
        const line = new THREE.Line( geometry, material );
        scene.add(line);
    }

    static generateCollisionSet(root: UnxSysNode, m: Map<string, UnxSysNode>, _viewRef?: UnxSysView) {
        root?.object && m.set(root.object.uuid, root);
        _viewRef && root.setViewRef(_viewRef);
        for (let i = 0; i < root.children.length; i++) {
            const child = root.children[i];
            UnxSysGraphUtil.generateCollisionSet(child, m, _viewRef);
        }
    }

    static generateJSXMap(root: UnxSysNode, m: Map<string, JSX.Element>) {
        root?.object && m.set(root.name, UnxSysReactUtil.getJSX(root));
        for (let i = 0; i < root.children.length; i++) {
            const child = root.children[i];
            UnxSysGraphUtil.generateJSXMap(child, m);
        }
    }


    static getRoot(node: UnxSysNode): UnxSysNode {
        if (!node.parent) {
            return node;
        } else return this.getRoot(node.parent);
    }

    static getPathName(node?: UnxSysNode) {
        if (!node) {
            return '';
        }
        else {
            let pathName = node.name;
            let parent: UnxSysNode | undefined = node;
            while (parent !== undefined) {
                parent = parent.parent;
                if (parent) {
                    pathName = `${parent.name}/${pathName}`;
                }
            }

            return pathName;
        }
    }

    static addHierarchyToScene(root: UnxSysNode, parent: THREE.Scene | THREE.Object3D, startingPoint?: THREE.Vector3, assetLib?: UnxSysAssetLibrary): void {
        const position = startingPoint ?? new THREE.Vector3(0,0,0);
        const mainObject = UnxSysGraphUtil.generateMesh(root, undefined, assetLib);
        root.object = mainObject;
        parent.add(mainObject);
        mainObject.position.copy(position);
        if (root.isLeaf()) {
            return;
        }
        
        const childGroup = new THREE.Group();
        const range =  root.children.length;
        const weight = root.weight;
        // const offset = -wFactor * (range-1) / 2;
        let x = -weight / 2;
        for (let i = 0; i < range; i++) {
            const child = root.children[i];
            const space =  weight * child.weight/(weight - 1);
            x += space / 2;
            const xFactor = position.x + x;
            const zFactor = position.z - 5 - (0.2 * i);
            const childPos = new THREE.Vector3(xFactor, 0, zFactor);
            UnxSysGraphUtil.addHierarchyToScene(child, parent, childPos, assetLib);
            UnxSysGraphUtil.connectPoints(childGroup, position, childPos);
            x += space / 2;
        }
        mainObject.attach(childGroup);
    }

}