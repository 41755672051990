import * as THREE from 'three';
import UnxSysGraphBuilder from '../graph/UnxSysGraphBuilder';
import UnxSysGraphUtil from '../graph/UnxSysGraphUtil';
import UnxSysAssetLibrary from './UnxSysAssetLibrary';
import {Font, FontLoader} from 'three/examples/jsm/loaders/FontLoader';
import UnxSysNode from './UnxSysNode';

export default class UnxSysView{
    private _windowSize: THREE.Vector2;
    // More like a core engine tbh
    private _mgr: THREE.LoadingManager;
    private _scene: THREE.Scene;
    private _root: THREE.Object3D;
    private _camera: THREE.Camera;
    private _renderer: THREE.Renderer;
    private _raycast: THREE.Raycaster;
    private _pointer: THREE.Vector2;
    private _ptrName?: THREE.Object3D;
    private _current: THREE.Vector3;
    private _currentScale = 1;
    // Camera Transit
    private _dest: THREE.Vector3 | undefined;
    private _targetScale = 1;
    private _lookFrameIdx: number = 0;

    private _lastFrameTime = 0;
    private _timeToAccumulate = 0;
    private FPS = 30;
    private _nodeMap: Map<string, UnxSysNode>;

    private _assetLibrary: UnxSysAssetLibrary;

    private _selectCb?: (n?: UnxSysNode) => void;

    constructor(canvasRef: HTMLCanvasElement, width?: number, height?: number) {
        this._windowSize = new THREE.Vector2(width, height);
        this._mgr = new THREE.LoadingManager();
        this._assetLibrary = new UnxSysAssetLibrary();
        this._nodeMap = new Map<string, UnxSysNode>();
        const postFontLoad = (font: Font) => {
            this._assetLibrary.font = font;

            const gb = new UnxSysGraphBuilder();
            gb.addGraphToRoot(this._root, this._assetLibrary);
            UnxSysGraphUtil.generateCollisionSet(gb.root, this._nodeMap, this);
            this._scene.add(this._root);

            
            this.cameraLookAt(gb._root);
        }
        var loader = new FontLoader(this._mgr);
            loader.load('testFont.json', function(response) {
            postFontLoad(response);
        });
        this._scene = new THREE.Scene();
        this.scene.background = new THREE.Color(0x00aeff);
        this._root = new THREE.Object3D();
        const aspect = width && height ? width / height : window.innerWidth / window.innerHeight
        this._camera = new THREE.PerspectiveCamera(45, aspect, 0.1, 1000);
        this._camera.position.set( 0, 5, 10 );
        this._camera.lookAt( 0, 0, 0 );
        this._camera.position.set( 0, 6, 10 );

        const floor = new THREE.PlaneGeometry(100, 100);
        const material = new THREE.MeshLambertMaterial({color:0x1a783d});
        const floorMesh = new THREE.Mesh(floor, material);
        floorMesh.position.y = -2;
        floorMesh.rotation.x = -Math.PI / 2;
        this._scene.add(floorMesh)

        this._raycast = new THREE.Raycaster();
        this._pointer = new THREE.Vector2();
        this._current = new THREE.Vector3();
        const renderer = new THREE.WebGLRenderer({
          canvas: canvasRef,
          antialias: false,
        });
    
        const w = width ?? window.innerWidth;
        const h = height ?? window.innerHeight;
        // const scale = Math.min(w, h) / Math.max(w, h);
        // this._scene.scale.setScalar(scale)
        renderer.setSize(w, h);
        this._renderer = renderer;

        const light = new THREE.PointLight(0xFFFFFF, 2);
        light.position.set(-25, 25, 25);
        this._scene.add(light);
    }

    get scene() : THREE.Scene {
      return this._scene;
    }

    get updateTime() {
        return 1000 / this.FPS;
    }

    set selectCb(cb: (n?: UnxSysNode) => void) {
        this._selectCb = cb;
    }

    updateValue(value: any) {
    }

    onMouseMove(x: number, y: number) {
        this._pointer.x = x;
        this._pointer.y = y;
        this.onHover();
    }

    onMouseClick(x: number, y: number) {
        this._pointer.x = x;
        this._pointer.y = y;
        this.processRayCaster();
    }

    onWindowResize(width: number, height:number) {
        this._windowSize.x = width;
        this._windowSize.y = height;
        this._renderer.setSize(width, height);
        if(this._camera instanceof THREE.PerspectiveCamera) {
            this._camera.aspect = width / height;
            this._camera.updateProjectionMatrix();
        }
    }

    public selectNode(n?: UnxSysNode) {
        this.cameraLookAt(n);
    }

    private cameraLookAt(n?:UnxSysNode) {
        if (this._selectCb) {
            this._selectCb(n);
        }
        const dest = n? n.object!.position : new THREE.Vector3(0,0,0);
        const scale = n?.depth ?? 1;
        const invDest = dest.clone().negate().multiplyScalar(scale);
        const magDiff = this._current.distanceTo(invDest);
        if (magDiff > 0.001) {
            this._targetScale = n?.depth ?? 1;
            this._dest = invDest;
            this._lookFrameIdx = 0;
        }
        if (this._ptrName) {
            this._ptrName.rotation.y = 0;
        }
        this._ptrName = n ? UnxSysGraphUtil.getNameObject(n) : undefined;
    }

    public navigateTo(n: UnxSysNode) {
        this.cameraLookAt(n);
    }

    private processTransit() {
        if (!this._dest) return;
        const step = this._lookFrameIdx / this.FPS;
        const newCurrent = UnxSysGraphUtil.navigateFromTo(this._root, this._current, this._dest, step);
        this._current.copy(newCurrent);
        this._currentScale = UnxSysGraphUtil.scaleFromTo(this._root, this._currentScale, this._targetScale, step);
        this._lookFrameIdx += 1;
        const magDiff = this._current.distanceTo(this._dest);
        if (magDiff < 0.001) {
            this._dest = undefined;
            this._targetScale = 1;
        }
    }

    private onHover() {
        this._raycast.setFromCamera(this._pointer, this._camera );
        const intersects = this._raycast.intersectObjects(this._root.children);
        let uuidIntersect = '';
        for ( let i = 0; i < intersects.length; i ++ ) {
            const obj = intersects[i].object as THREE.Object3D;
            if (obj instanceof THREE.Mesh) {
                uuidIntersect = obj.uuid;
                break;
            }
        }
        for (let i = 0; i < this._root.children.length; i++) {
            const meshObj = this._root.children[i];
            if (meshObj instanceof THREE.Mesh ) {
                if (uuidIntersect === meshObj.uuid) {
                    meshObj.material.color.set(0xff63be);
                }
                else {
                    meshObj.material.color.set(this._assetLibrary.pinkColor);
                }
            }
        }
    }

    private processRayCaster() {
        this._raycast.setFromCamera(this._pointer, this._camera );
        const intersects = this._raycast.intersectObjects(this._root.children);
        let uuidIntersect = '';
        for ( let i = 0; i < intersects.length; i ++ ) {
            const obj = intersects[i].object as THREE.Object3D;
            if (obj instanceof THREE.Mesh) {
                uuidIntersect = obj.uuid;
                break;
            }
        }
        if (!uuidIntersect) {
            this.cameraLookAt();
        }
        for (let i = 0; i < this._root.children.length; i++) {
            const meshObj = this._root.children[i];
            if (meshObj instanceof THREE.Mesh ) {
                if (uuidIntersect === meshObj.uuid) {
                    this.cameraLookAt(this._nodeMap.get(uuidIntersect));
                }
            }
        }
    }

    update(time: number) {
        const frameTime = time - this._lastFrameTime;
        this._timeToAccumulate += frameTime;
        if (this._timeToAccumulate > this.updateTime) {
            this._dest && this.processTransit();
            if (this._ptrName) {
                this._ptrName.rotation.y += 0.08;
            }
            this._renderer.render(this._scene, this._camera);
            this._timeToAccumulate = 0;
        }

        this._lastFrameTime = time;

        requestAnimationFrame(this.update.bind(this));
    }
}