import React from 'react';
import * as THREE from 'three';
import UnxSysNode from './UnxSysNode';
import UnxSysView from './UnxSysView';


interface SceneProps {
    width?: number;
    height?: number;
    mouseOffsetX?: number;
    mouseOffsetY?: number;
    resizeCb?: () => THREE.Vector2;
    selectNodeCb?: (n?: UnxSysNode) => void;
}

export default class UnxSysScene extends React.Component<SceneProps> {
    _canvasRef: React.RefObject<HTMLCanvasElement>;
    _view: UnxSysView | undefined;
    _canvasDimensions: THREE.Vector2;
    _mouseOffset: THREE.Vector2;
    state: any;

    constructor(props: SceneProps) {
        super(props);
        this._canvasRef = React.createRef();
        this._canvasDimensions = new THREE.Vector2(props.width, props.height);
        this._mouseOffset = new THREE.Vector2(props.mouseOffsetX, props.mouseOffsetY);

        this.state = {
          initialized: false,
        };
    }

    init = () => {
        const canvas = this._canvasRef.current;
        this._view = new UnxSysView(canvas!, this.props.width, this.props.height);
        if (this.props.selectNodeCb) {
            this._view.selectCb = this.props.selectNodeCb;
        }

        // Init any event listeners
        // window.addEventListener('mousemove', this.mouseMove);
        this.setState({initialized: true});
        this._view?.update(0.00);
        window.addEventListener('resize', this.handleResize);
        window.addEventListener('click', this.onClick)
        window.addEventListener('mousemove', this.mouseMove)
    };

    componentDidMount() {
        if (!this.state.initialized) {
          this.init();
        }
    }

    componentDidUpdate() {
    }

    componentWillUnmount() {
        // Remove any event listeners
        // window.removeEventListener('mousemove', this.mouseMove);
        window.removeEventListener('resize', this.handleResize);
        window.removeEventListener('click', this.onClick)
    }

    // // ******************* EVENT LISTENERS ******************* //
    mouseMove = (_event: any) => {
        const x = ( (_event.clientX + this._mouseOffset.x) / this._canvasDimensions.x ?? window.innerWidth ) * 2 - 1;
        const y = - ( (_event.clientY - this._mouseOffset.y) / this._canvasDimensions.y ?? window.innerHeight ) * 2 + 1;
        this._view?.onMouseMove(x, y);
    }

    handleResize = () => {
        const dimensions = this.props.resizeCb? this.props.resizeCb() : new THREE.Vector2(this._canvasDimensions.x ?? window.innerWidth,  this._canvasDimensions.y ?? window.innerHeight);
        this._canvasDimensions.x = dimensions.x;
        this._canvasDimensions.y = dimensions.y;
        this._view?.onWindowResize( dimensions.x, dimensions.y);
    };

    onClick = (_event: any) => {
        const x = ( (_event.clientX + this._mouseOffset.x) / this._canvasDimensions.x ?? window.innerWidth ) * 2 - 1;
        const y = - ( (_event.clientY - this._mouseOffset.y) / this._canvasDimensions.y ?? window.innerHeight ) * 2 + 1;
        if (Math.min(x, y) < -1 || Math.max(x, y) > 1) {
            return;
        }
        this._view?.onMouseClick(x, y);
    };

    selectNode(n:UnxSysNode) {
        this._view?.selectNode(n);
    }

    render() {
        return (
            <div className="canvasContainer">
                <canvas ref={this._canvasRef} />
            </div>
        );
    }
}