import React, { useEffect } from 'react';
import UnxSysGraphUtil from '../graph/UnxSysGraphUtil';
import UnxSysNode from '../scene/UnxSysNode';

export const DisplayPanel: React.FC<{node?:UnxSysNode}> = ({node}) => {
    const [nodeMap, setNodeMap] = React.useState<Map<string, JSX.Element>>();
    useEffect (() => {
        if (nodeMap === undefined) {
            if (node) {
                const map = new Map<string, JSX.Element>();
                const root = UnxSysGraphUtil.getRoot(node!);
                UnxSysGraphUtil.generateJSXMap(root, map);
                setNodeMap(map);
            }
        }
    }, [nodeMap, node]);
    return (<>{node && nodeMap?.get(node.name)}</>);
}