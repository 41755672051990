import React from 'react';
import * as THREE from 'three';
import UnxSysNode from '../scene/UnxSysNode';
import UnxSysScene from '../scene/UnxSysScene';
import { DisplayPanel } from './UnxSysDisplayPanel';

interface HomePageState {
    selectedNode?: UnxSysNode
}

type LayoutType = 'wide' | 'narrow' | 'mid';

export default class HomePage extends React.Component {
    state: HomePageState;
    _size: THREE.Vector2;
    _layoutType: LayoutType;

    constructor(props: any) {
        super(props);

        this.state = {};
        this._layoutType = this.getLayout();
        this._size = this.canvasSize();
    }

    handleResize = () => {
        this._layoutType = this.getLayout();
        this._size = this.canvasSize();
        const curNode = this.state.selectedNode;
        this.setState({  key: Math.random(), selectedNode: curNode });
    };

    resizeCb = () => {
        return new THREE.Vector2(this._size.x, this._size.y);
    }

    canvasSize() {
        switch(this._layoutType) {
            case 'wide': {
                return new THREE.Vector2(0.49* window.innerWidth,window.innerHeight);
            }
            case 'narrow': {
                return new THREE.Vector2(window.innerWidth, 0.49*window.innerHeight);
            }
            default: {
                return new THREE.Vector2(0.7* window.innerWidth, window.innerHeight);
            }
        }
    }

    getLayout() {
        const aspect = window.innerWidth / window.innerHeight;
        if (aspect < 0.75) {
            return 'narrow';
        } else if (aspect > 1.5) {
            return 'wide'
        }
        return 'mid';
    }

    getCanvasWidth() {
        switch(this._layoutType) {
            case 'wide': {
                return 0.49 * window.innerWidth;
            }
            case 'narrow': {
                return  "100%";
            }
            default: {
                return  "70%"
            }
        }
    }

    getPanelWidth() {
        switch(this._layoutType) {
            case 'wide': {
                return "49%";
            }
            case 'narrow': {
                return  "100%";
            }
            default: {
                return  "29%";
            }
        }
    }

    componentDidMount() {
        window.addEventListener('resize', this.handleResize);
    }

    componentWillUnmount() {
        window.addEventListener('resize', this.handleResize);
    }

    selectNodeCb = (n?: UnxSysNode) => {
        this.setState({selectedNode: n});
    };

    render() {
        const size = this._size;
        const node = this.state.selectedNode;
        return (
            <div className="HomePage">
                <div style={{width: this.getCanvasWidth(), height:  size.y, float: 'left'}} >
                    <UnxSysScene width={size.x} height={size.y} resizeCb={this.resizeCb} selectNodeCb={this.selectNodeCb}/>
                </div>
                {/* <div style={{position:'absolute', top: this.getTop(), left: this.getPanelWidth()}} > */}
                <div style={{ float: "right", width:this.getPanelWidth()}}>
                    <DisplayPanel node={node} />
                </div>
            </div>
        );
    }
}