import * as THREE from 'three';
import { Font } from 'three/examples/jsm/loaders/FontLoader';

export default class UnxSysAssetLibrary {
    public phongPink : THREE.Material;
    public textMat : THREE.Material;
    public textMatShadow : THREE.Material;
    public lineMat : THREE.Material;
    public whiteMat : THREE.Material;
    public blueMat : THREE.Material;
    public redMat : THREE.Material;
    public blackMat : THREE.Material;
    public pinkColor: THREE.ColorRepresentation;
    public extrudeSettings: THREE.ExtrudeGeometryOptions;
    private _font?: Font;
    constructor() {
        this.pinkColor = 0x8c3cc9;
        this.phongPink = new THREE.MeshLambertMaterial({color: this.pinkColor});
        this.textMat = new THREE.MeshPhongMaterial({color: 0xffffff});
        this.textMatShadow = new THREE.MeshPhongMaterial({color: 0xAAAAAA});
        this.lineMat = new THREE.LineBasicMaterial( { color: 0xffffff } );
        this.extrudeSettings = {
            steps: 1,
            depth: 0.1,
            bevelEnabled: false,
        };

        this.whiteMat = new THREE.MeshLambertMaterial({color:0xFFFFFF});
        this.blueMat = new THREE.MeshLambertMaterial({color:0x5440e6});
        this.redMat = new THREE.MeshLambertMaterial({color:0xde3e59});
        this.blackMat = new THREE.MeshLambertMaterial({color:0x0F0F0F});
    }

    set font(font: Font | undefined) {
        this._font = font;
    }
    get font() {
        return this._font;
    }
};