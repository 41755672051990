import * as THREE from 'three';
import {NodeStaticConfig } from '../graph/JamesConfig';
import UnxSysView from './UnxSysView';


export type GeometryConfig = {
    geoType: string,
    points?: Array<any>,
    materials?: Array<string>
}

export default class UnxSysNode {
    private _config?: NodeStaticConfig
    private _name: string = 'node';
    private _children: Array<UnxSysNode>;
    private _parent: UnxSysNode | undefined;
    private _object: THREE.Object3D | undefined;
    private _viewRef?: UnxSysView;
    private _geoConfig?: GeometryConfig;
    private _jsx?: JSX.Element;

    constructor() {
        this._children = new Array<UnxSysNode>();
    }

    public isLeaf() {
        return this._children.length === 0;
    }

    get depth(): number {
        if (!this._parent) {
            return 1;
        } else {
            return 1 + this._parent.depth;
        }
    }

    get parent() {
        return this._parent;
    }
    set parent(parent: UnxSysNode | undefined) {
        this._parent = parent;
    }

    get children() {
        return this._children;
    }

    get object() {
        return this._object;
    }
    set object(obj: THREE.Object3D | undefined){
        this._object = obj;
    }

    get config() {
        return this._config;
    }

    get name() {
        return this._name;
    }

    set name(n: string) {
        this._name = n;
    }

    get weight() {
        let local = 1;
        for (let i = 0; i < this.children.length; i++) {
            const c = this.children[i];
            local += c.weight;
        }
        return local;
    }

    public addChild(child: UnxSysNode) {
        child.parent = this;
        this._children.push(child);
    }

    public setConfig(config: NodeStaticConfig) {
        this._config = config;
    }

    public setGeomety(config: GeometryConfig) {
        this._geoConfig = config;
    }

    public setJsx(jsx: JSX.Element) {
        this._jsx = jsx;
    }

    get jsx() {
        return this._jsx;
    }

    public get geoConfig() {
        return this._geoConfig;
    }

    public setViewRef(ref: UnxSysView) {
        this._viewRef = ref;
    }

    public select() {
        this._viewRef && this._viewRef.navigateTo(this);
    }
};