import UnxSysNode from "../scene/UnxSysNode";
import UnxSysGraphUtil from "./UnxSysGraphUtil";

import * as THREE from 'three';
import UnxSysAssetLibrary from "../scene/UnxSysAssetLibrary";
import JamesConfig, { NodeStaticConfig } from "./JamesConfig";

export default class UnxSysGraphBuilder {
    _root: UnxSysNode;
    constructor() {
        this._root = this.generateFromConfig(JamesConfig.rootNode);
    }

    private generateFromConfig (cfg: NodeStaticConfig, parent?: UnxSysNode): UnxSysNode {
        const node = new UnxSysNode();
        node.name = cfg.title3d ?? cfg.title;
        cfg.body && node.setConfig(cfg);
        cfg.geo && node.setGeomety(cfg.geo);
        if (cfg.children?.length) {
            for (let i = 0; i < cfg.children.length; i++) {
                const childCfg = cfg.children[i];
                const childNode = this.generateFromConfig(childCfg, node);
                node.addChild(childNode);
            }
        }
        return node;
    }

    get root() {
        return this._root;
    }

    public addGraphToRoot(root: THREE.Object3D, assetLib: UnxSysAssetLibrary) {
        UnxSysGraphUtil.addHierarchyToScene(this._root, root, new THREE.Vector3(0,0,0), assetLib);
    }
}