import UnxSysNode from "../scene/UnxSysNode";
import { NodeBodyStaticText, NodeBodyStaticImage, NodeBodyVideoFile, NodeBodyStaticLink } from "./JamesConfig";
import UnxSysGraphUtil from "./UnxSysGraphUtil";

export class UnxSysReactUtil {
    public static getJSX(node: UnxSysNode) {
        const children = node?.config?.body ?? [];
        const childDivs: React.ReactNode[] = [];
        for (let i = 0; i < children.length; i++) {
            const childDetail = children[i];
            if ((childDetail as NodeBodyStaticText).text) {
                childDivs.push(<div 
                    key={`${node?.name}_${i}`}
                    className="detailText"> 
                        {(childDetail as NodeBodyStaticText).text}
                </div>);
            } else if ((childDetail as NodeBodyStaticImage).imageUrl) {
                childDivs.push(<div 
                    key={`${node?.name}_${i}`}
                    className="detailImgDiv"> 
                    <img className='detailImage' src={(childDetail as NodeBodyStaticImage).imageUrl} alt={(childDetail as NodeBodyStaticImage).imageLabel}/>
                    <div className='detailImageLabel'> {(childDetail as NodeBodyStaticImage).imageLabel} </div>
                </div>);
            } else if ((childDetail as NodeBodyVideoFile).movUrl) {
                childDivs.push(<div 
                    key={`${node?.name}_${i}`}
                    className="detailImgDiv"> 
                    <video controls className='detailImage' src={(childDetail as NodeBodyVideoFile).movUrl} />
                    <div className='detailImageLabel'> {(childDetail as NodeBodyVideoFile).movLabel} </div>
                </div>);
            } else if ((childDetail as NodeBodyStaticLink).linkUrl) {
                childDivs.push(<div 
                    key={`${node?.name}_${i}`}
                    className="detailText"> 
                    <a href={(childDetail as NodeBodyStaticLink).linkUrl}>
                        {(childDetail as NodeBodyStaticLink).linkLabel}
                    </a>
                </div>);
            }
        }

        const childNodes = node?.children ?? [];
        const childLinks: React.ReactNode[] = [];

        for (let i = 0; i < childNodes.length; i++) {
            const child = childNodes[i];
            const percent = (90/childNodes.length).toFixed() + '%';
            childLinks.push(<button
                className='detailBtn'
                style={{width: percent}}
                key={`${node?.name}_${child.name}`}
                onClick={() => child.select()}> 
                {child.name} 
            </button>)
        }

        return node ? <div className='displayBox'>
            <div className='detailTitle'> 
                {UnxSysGraphUtil.getPathName(node)}
            </div>
            <div className='detailDetailLol'>
                {childDivs}
            </div>
            { childLinks.length > 0 &&
            <div style={{textAlign:'center'}}>
                <div>goto </div>
                {childLinks}
            </div>
            }
            { node.parent && <div style={{textAlign:'center'}}>
                <div>back  </div>
                <button
                className='detailBtn'
                key={`${node?.name}_${node.parent.name}`}
                onClick={() => node.parent?.select()}> 
                {node.parent?.name ?? 'back'} 
                </button>
            </div>}
        </div> : <></>;
    }
}